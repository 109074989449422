
import * as customProviderRuntime$NLGa6a34Q8 from '/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/node_modules/sitis-nuxt-3/providers/imageProvider.ts'
import * as localProviderRuntime$NLGa6a34Q8 from '/var/www/new.mia-sofia.ru/miasofiaru-front-20250401145021/node_modules/sitis-nuxt-3/providers/imageProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "imageProvider",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['imageProvider']: { provider: customProviderRuntime$NLGa6a34Q8, defaults: {"modifiers":{"format":"webp"},"baseURL":"https://mia-sofia.ru/api/storage/image/","emptyImagePlaceholder":"/img/placeholder.svg"} },
  ['localProvider']: { provider: localProviderRuntime$NLGa6a34Q8, defaults: undefined }
}
        